import React from "react";
import { Link } from "gatsby";
import useWrapperContext from "../Layout";
import { _localizedSlug } from "../../core/utils";
// import PubSub from "pubsub-js"

// import { ContextHoc } from "../../context/ContextHoc"
const locales = require("../../../config/i18n");

const LocaleSwitcher = () => {
  const { lang, alternate_language, isHome } = useWrapperContext();
  // console.log(alternate_language);

  const renderLanguages = () => {
    const languages = Object.values(locales).map((li, key) => {
      const isActive = li.locale === lang;
      const path = isActive ? "/" : _localizedSlug(alternate_language, isHome);
      // console.log(path);
      return (
        <li key={key} className='btn '>
          <Link
            to={path}
            className={`${
              isActive ? "pointer-events-none- text-gray" : ""
            } lowercase`}>
            <span>{li.label}</span>
          </Link>
        </li>
      );
    });
    return languages;
  };

  return (
    <ul className='locale-switcher flex justify-end hidden--'>
      {renderLanguages()}
    </ul>
  );
};

export default LocaleSwitcher;
