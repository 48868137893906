import React from "react";
import { RichText, Elements } from "prismic-reactjs";
import useWrapperContext from "../components/Layout";
const locales = require("../../config/i18n");

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf("/") + 1);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const _getLocalizedText = (text) => {
  const { lang } = useWrapperContext();
  console.log(lang);
  // const _WrapperContext = useContext(WrapperContext);
  // const { locale } = _WrapperContext;
  // console.log("locale",locale, text)
  return locales[lang] && locales[lang][text] ? locales[lang][text] : text;
};
export const _localizedHome = () => {
  const { lang } = useWrapperContext();
  // console.log(lang);
  if (!lang) return "/";
  return locales[lang].default ? "/" : `/${locales[lang].path}`;
};
export const _localizedSlug = (node, isHome) => {
  // console.log(node);
  if (!node || !node.uid) return "/";
  const link = linkResolver(node);
  if (isHome) {
    return locales[node.lang].default ? "/" : `/${locales[node.lang].path}`;
  }
  return locales[node.lang].default
    ? `${link}`
    : `/${locales[node.lang].path}${link}`;
};

export const linkResolver = (doc) => {
  // console.log(doc);
  if (doc.type === "page" && doc.data?.home_page) {
    return "/";
  }
  switch (doc.type) {
    case "category":
      return `/category/${doc.uid}`;
    case "project":
      return `/project/${doc.uid}`;

    default:
      return `/${doc.uid}`;
  }
};

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};
const isNumeric = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

// -- HTML Serializer
export const htmlSerializer = function (type, element, content, children, key) {
  var props = {};

  switch (type) {
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: "noopener" } : {};
      props = Object.assign(
        {
          className: "link-class",
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      );
      return React.createElement("a", propsWithUniqueKey(props, key), children);

    case Elements.listItem: // Unordered List Item
      // console.log(element);
      //First letter is a number > tabulation css
      const firstWord = element.text.split(" ")[0];
      const firstLetter = firstWord.split("")[0];
      const leftOver = element.text.replace(firstWord, "");

      if (isNumeric(firstLetter)) {
        // console.log(firstWord, "is num");
        const liHtml = React.createElement("div", {
          dangerouslySetInnerHTML: {
            __html: `<div class="x"><div class="date">${firstWord}</div><div class="leftOver">${leftOver}</div></div>`,
          },
        });
        return React.createElement(
          "li",
          propsWithUniqueKey(props, key),
          liHtml
        );
      } else {
        return React.createElement(
          "li",
          propsWithUniqueKey(props, key),
          children
        );
      }

    default:
      // Always include a default that returns null
      return null;
  }
};
