import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";

const WrapperContext = React.createContext();

const query = graphql`
  query {
    settings: prismicSettings {
      ...settings
    }
  }
`;

const Layout = ({ children, pageContext }) => {
  // console.log(pageContext);
  const { lang, alternate_language } = pageContext;
  const isHome = pageContext?.isHome || false;
  const { settings } = useStaticQuery(query);
  const [direction, setDirection] = useState();
  const [isMobile, setIsMobile] = useState();
  // console.log(alternate_language);
  useEffect(() => {
    _onScroll();
    _format();
    window.addEventListener("scroll", _onScroll);
    window.addEventListener("resize", _format);
    if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true);

    return () => {
      window.removeEventListener("scroll", _onScroll);
      window.removeEventListener("resize", _format);
    };
  }, []);

  let _prevScrollTop = 0;
  const _onScroll = () => {
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up");

    if (window.pageYOffset === 0) {
      setDirection("");
    }

    _prevScrollTop = window.pageYOffset;
  };
  const _format = () => {
    // console.log("format");
    // const ww = window.innerWidth;
    // const wh = window.innerHeight;
    document.documentElement.style.setProperty(
      "--appHeight",
      window.innerHeight + "px"
    );

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--headerHeight",
      headerBounding.height + "px"
    );
  };

  return (
    <WrapperContext.Provider
      value={{ settings, isMobile, isHome, lang, alternate_language }}>
      <div id='page'>
        <Header direction={direction} />
        <main>{children}</main>
        {/* <Footer /> */}
        {/* {!isHome && <Footer />} */}
      </div>
    </WrapperContext.Provider>
  );
};

// export { WrapperContext, Layout };
export { WrapperContext, Layout };
export default function useWrapperContext() {
  return useContext(WrapperContext);
}
