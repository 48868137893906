import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { linkResolver, _localizedSlug } from "../core/utils";
import useWrapperContext from "./Layout";

const query = graphql`
  query {
    categoriesEn: prismicMenu(
      uid: { eq: "menu-categories" }
      lang: { eq: "en-gb" }
    ) {
      data {
        menu_items {
          title {
            text
          }
          item {
            uid
            type
            lang
          }
        }
      }
    }
    categoriesFr: prismicMenu(
      uid: { eq: "menu-categories" }
      lang: { eq: "fr-fr" }
    ) {
      data {
        menu_items {
          title {
            text
          }
          item {
            uid
            type
            lang
          }
        }
      }
    }
    projectsEn: prismicMenu(
      uid: { eq: "menu-projects" }
      lang: { eq: "en-gb" }
    ) {
      data {
        menu_items {
          title {
            text
          }
          item {
            uid
            type
            lang
          }
        }
      }
    }
    projectsFr: prismicMenu(
      uid: { eq: "menu-projects" }
      lang: { eq: "fr-fr" }
    ) {
      data {
        menu_items {
          title {
            text
          }
          item {
            uid
            type
            lang
          }
        }
      }
    }
  }
`;

const MenuProjects = () => {
  const { lang } = useWrapperContext();
  const { categoriesEn, categoriesFr, projectsEn, projectsFr } =
    useStaticQuery(query);

  const categories = lang === "fr-fr" ? categoriesFr : categoriesEn;
  const projects = lang === "fr-fr" ? projectsFr : projectsEn;
  // console.log(projects);
  // const categoriesByLang = categories.data.menu_items.filter(
  //   (el) => el.item.lang === lang
  // );
  // const projectsByLang = projects.data.menu_items.filter(
  //   (el) => el.item.lang === lang
  // );
  // console.log(lang);
  // console.log(categoriesByLang);
  // console.log(projectsByLang);
  let delay = 30;
  const gap = 30;
  //100 + i0 + "ms"
  return (
    <nav className='nav-secondary'>
      <div className='nav-group mb-lg text-right'>
        <div
          className='menu-type'
          style={{
            transitionDelay: delay + "ms",
          }}>
          Categories :
        </div>
        <ul className=''>
          {categories.data.menu_items.map((li, i) => {
            // delay = delay + gap + i * gap;
            delay = gap * i + gap * 2;
            return (
              <li key={i} className='menu-item'>
                <Link
                  to={_localizedSlug(li.item)}
                  className='text-gray'
                  style={{
                    transitionDelay: delay + "ms",
                  }}>
                  {li.title.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>

      <div className='nav-group text-right'>
        <div
          className='menu-type'
          style={{
            transitionDelay: delay + gap + "ms",
          }}>
          Index :
        </div>
        <ul className=''>
          {projects.data.menu_items.map((li, i) => {
            delay += gap;
            return (
              <li key={i} className='menu-item'>
                <Link
                  to={_localizedSlug(li.item)}
                  className='text-gray'
                  style={{
                    transitionDelay: delay + "ms",
                  }}>
                  {li.title.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default MenuProjects;
