import React, { useContext, useState, useEffect } from "react";
//import { StaticQuery, graphql } from "gatsby"
import { useStaticQuery, graphql, Link } from "gatsby";
import clsx from "clsx";
import PubSub from "pubsub-js";
import useWrapperContext, { WrapperContext } from "./Layout";
import { navigate } from "@reach/router";
import scrollTo from "gatsby-plugin-smoothscroll";
import MenuProjects from "./MenuProjects";
import { linkResolver, _localizedHome, _localizedSlug } from "../core/utils";
import { _getLocalizedText } from "../core/utils";
import LocaleSwitcher from "./ui/LocaleSwitcher";
// import Burger from "./ui/Burger";

const query = graphql`
  query {
    prismicMenuEn: prismicMenu(
      uid: { eq: "menu-header" }
      lang: { eq: "en-gb" }
    ) {
      ...menu
    }
    prismicMenuFr: prismicMenu(
      uid: { eq: "menu-header" }
      lang: { eq: "fr-fr" }
    ) {
      ...menu
    }
  }
`;

const Header = ({ direction }) => {
  const { settings, isHome, lang } = useWrapperContext();
  const { prismicMenuEn, prismicMenuFr } = useStaticQuery(query);
  const prismicMenu = lang === "fr-fr" ? prismicMenuFr : prismicMenuEn;

  const [navActive, setNavActive] = useState();

  useEffect(() => {
    const tokenR = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      setNavActive(false);
      _format();
    });

    _format();
    window.addEventListener("resize", _format);

    return () => {
      PubSub.unsubscribe(tokenR);
      // PubSub.unsubscribe(tokenA);
      window.removeEventListener("resize", _format);
    };
  }, []);

  const _format = () => {
    // return;
    let menuItemProjects = document.querySelector(
      ".menu-item--projects---anchor"
    );
    if (!menuItemProjects)
      menuItemProjects = document.querySelector(".menu-item--projets---anchor");
    if (!menuItemProjects) return;

    const { x } = menuItemProjects.getBoundingClientRect();

    // console.log(x);
    const subMenu = document.querySelector(".sub-menu");
    subMenu.style.paddingRight = window.innerWidth - x + "px";
  };

  // const _handleClickAbout = () => {
  //   if (isHome) {
  //     navigate("/projects");
  //     setTimeout(() => {
  //       scrollTo("#about");
  //     }, 250);
  //   } else {
  //     scrollTo("#about");
  //   }
  // };
  // console.log(lang);
  // console.log(prismicMenu);
  return (
    <header
      className={clsx(
        "px-md text-_lg md:text-lg z-50",
        direction,
        navActive ? "is-nav" : ""
        // isAbout ? "is-about" : ""
      )}>
      <div
        className={clsx(
          "sub-menu fixed top-0 right-0 w-screen h-screen overflow-y-auto text-right p-md ",
          navActive ? "is-active" : ""
        )}>
        <MenuProjects />
      </div>

      <div className='row justify-between'>
        <div className='col-md-4 col-xs'>
          <div className='site-name '>
            <Link to={_localizedHome()}>{settings.data.title.text}</Link>
          </div>
        </div>
        <div className='col-md-6 col-xs-7'>
          <div className='sm-only'>
            <LocaleSwitcher />
          </div>
          <nav className='nav-primary '>
            <ul className='flex flex-col items-end md:items-start md:flex-row md:justify-end'>
              <li className='hidden-sm'>
                <button
                  className='menu-toggle cursor-pointer'
                  onClick={() => setNavActive(!navActive)}>
                  (
                  {navActive
                    ? _getLocalizedText("close")
                    : _getLocalizedText("filter")}
                  )
                </button>
              </li>

              {prismicMenu.data.menu_items.map((li, i) => (
                <li key={i} className='menu-item-container'>
                  <div
                    className={clsx("menu-item", `menu-item--${li.item.uid}`)}>
                    {i === 0 && (
                      <button
                        className='menu-toggle cursor-pointer sm-only'
                        onClick={() => setNavActive(!navActive)}>
                        (
                        {navActive
                          ? _getLocalizedText("close")
                          : _getLocalizedText("filter")}
                        )
                      </button>
                    )}
                    {li.item.uid === "about" ? (
                      <Link
                        to={_localizedSlug(li.item)}
                        className={`menu-item--${li.item.uid}`}>
                        {li.title.text}
                      </Link>
                    ) : (
                      <Link
                        to={_localizedSlug(li.item)}
                        className={`menu-item--${li.item.uid}---anchor`}>
                        {li.title.text}
                      </Link>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className='col-md-2 hidden-sm'>
          <LocaleSwitcher />
        </div>
      </div>
      {/* <Burger /> */}
    </header>
  );
};

export default Header;
