/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react";
import Helmet from "react-helmet";
import PubSub from "pubsub-js";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import { Layout } from "./src/components/Layout";

// import { withPrefix } from 'gatsby'
// const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>

// export default wrapPageElement

const _wrapRootElement = ({ element }) => {
  return (
    <PreviewStoreProvider>
      <Helmet>
        <script src='//unpkg.com/pace-js@1.0.2/pace.min.js'></script>
        <script
          defer
          src='//unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js'></script>
        {/* <script
          defer
          src='//unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js'></script> */}
      </Helmet>
      {element}
    </PreviewStoreProvider>
  );
};

const _wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

const _onClientEntry = () => {
  //console.log("We've started!")
  // callAnalyticsAPI()
  const isTouch = "ontouchstart" in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add("touch");
  } else {
    document.documentElement.classList.add("no-touch");
  }
};

const _onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish("ROUTE_UPDATE");
};

export { _wrapRootElement, _wrapPageElement, _onClientEntry, _onRouteUpdate };
