import React, { useEffect, useState } from "react";
import clsx from "clsx";

const Journal = ({ input }) => {
  // console.log(input);
  const { showIframe, setShowIframe } = input;
  const [iframeHeight, setIframeHeight] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setIsLoading(true);
    _format();
  }, [showIframe]);

  useEffect(() => {
    _format();
    window.addEventListener("resize", _format);
    return () => window.removeEventListener("resize", _format);
  }, []);

  const _format = () => {
    if (!showIframe) return;
    const iframe = document.querySelector(".instagram-feed iframe");
    iframe.onload = () => {
      setIsLoading(false);
    };
    const iframeCardWidth = (iframe.getBoundingClientRect().width - 0) / 3;
    const _iframeHeight = iframeCardWidth * 34 + 10 * 4;
    setIframeHeight(_iframeHeight);
  };

  return (
    <div
      className={clsx(
        "instagram-feed fixed left-0 top-0 w-screen h-screen overflow-y-auto bg-white z-50 px-md pb-md",
        showIframe ? "is-active" : ""
      )}>
      <div className='row'>
        <div className='col-md-10 col-xs-12'>
          <div className='instagram-feed-header text-_lg md:text-lg text-right py-md-- bg-white flex justify-between z-50'>
            <div className='site-name'>Sisto Studios</div>
            <div className='bg-white' style={{ zIndex: 251 }}>
              <button onClick={() => setShowIframe(false)}>(close)</button>
              <span>Journal</span>
            </div>
          </div>
          {showIframe && (
            <div
              className={clsx(
                "iframe-container",
                isLoading ? "is-loading" : ""
              )}>
              <a
                href='https://www.instagram.com/sistonicolas'
                target='_blank'
                rel='noopener, noreferrer'>
                <iframe
                  style={{ height: iframeHeight + "px" }}
                  src='https://snapwidget.com/embed/946850'
                  className='snapwidget-widget w-full'
                  frameBorder='0'
                  scrolling='yes'></iframe>
              </a>

              {isLoading && (
                <div className='loader absolute w-full h-screen- top-0 left-0 text-_lg md:text-lg flex align-baseline'>
                  <span className='mr-md'>Loading</span>
                  <div className='flex items-end pb-sm'>
                    <div
                      className={`w-sm h-sm bg-black rounded-full mr-sm animate-bounce`}></div>
                    <div
                      className={`w-sm h-sm bg-black rounded-full mr-sm animate-bounce200`}></div>
                    <div
                      className={`w-sm h-sm bg-black rounded-full animate-bounce400`}></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Journal;
