//const website = require('./website')

module.exports = {
  "en-gb": {
    default: true,
    label: "En",
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_GB",
    projects: "Projects",
    about: "About",
    contact: "Contact",
    filter: "filter",
    close: "close",
  },
  "fr-fr": {
    default: false,
    label: "Fr",
    path: "fr",
    locale: "fr-fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    projects: "Projets",
    about: "À propos",
    contact: "Contact",
    filter: "filtre",
    close: "fermer",
  },
};
