import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useInView } from "react-intersection-observer";
import PubSub from "pubsub-js";
import clsx from "clsx";
import { RichText } from "prismic-reactjs";
import Img from "gatsby-image";
import Journal from "./Journal";
import useWrapperContext from "./Layout";

const query = graphql`
  query {
    prismicAboutEn: prismicAbout(lang: { eq: "en-gb" }) {
      data {
        ...about
      }
    }
    prismicAboutFr: prismicAbout(lang: { eq: "fr-fr" }) {
      data {
        ...about
      }
    }
    # prismicAbout {
    #   data {
    #     ...about
    #   }
    # }
  }
`;

const h2Style = {
  borderBottom: "1px solid",
};

const About = () => {
  // const { prismicAbout } = input;
  const { lang } = useWrapperContext();
  const { prismicAboutEn, prismicAboutFr } = useStaticQuery(query);
  const prismicAbout = lang === "fr-fr" ? prismicAboutFr : prismicAboutEn;
  const { bio, contact, image } = prismicAbout.data;
  // console.log(input);
  const [showIframe, setShowIframe] = useState();
  // const [iframeHeight, setIframeHeight] = useState();

  const [ref, inView] = useInView({
    threshold: 1,
    delay: 100,
    trackVisibility: true,
    initialInView: true,
  });

  useEffect(() => {
    // console.log(inView);
    // PubSub.publish("ABOUT_INVIEW", inView);
  }, [inView]);

  return (
    <div id='about' className={clsx("about p-md min-h-screen-")} ref={ref}>
      <Journal input={{ showIframe, setShowIframe }} />

      <div className='row md:h-full'>
        <div className='col-md-7 col-xs-12'>
          <div className='md:flex flex-col justify-between h-main'>
            <div className='text-_lg md:text-lg about-texte mb-lg md:mb-md'>
              {RichText.render(bio.raw)}
            </div>
            <div className='contacts'>
              <div className='row'>
                <div className='col-md-8 col-xs-12'>
                  <div className='image -mx-md mb-md md:mb-0 md:mx-0'>
                    <Img {...image} />
                  </div>
                </div>
                <div className='col-md-4 col-xs-12'>
                  <div className='text-md'>
                    <h2 className='pb-xs mb-xs text-md' style={h2Style}>
                      Contact
                    </h2>
                    <div className='h-30vh md:h-auto'>
                      {RichText.render(contact.raw)}
                    </div>
                  </div>
                  <div className='absolute bottom-0 right-0'>
                    <button
                      className='text-gray text-_lg md:text-lg'
                      onClick={() => setShowIframe(true)}>
                      Journal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3 hidden-sm'></div>
        <div className='col-md-2 col-xs-12'>
          <div className='h-full'>
            <div className='terms md:absolute bottom-0 right-0 text-md pt-lg md:pt-0'>
              <p>® Sisto Studio All rights reserved</p>
              <p>
                Design by{" "}
                <span>
                  <a href='https://laandstudio.com' target='_blank'>
                    Laand Studio
                  </a>
                </span>
                , Code by{" "}
                <span>
                  <a href='https://ahmedghazi.com' target='_blank'>
                    Ahmed Ghazi
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
